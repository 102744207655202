'use client'

import React from "react";

import { useProductPurchaseContext } from "@context/product-purchase";

const PriceButtonGroupButton = ({ index, children }: { index: number, children: React.ReactElement }) => {

    const { product, amount, setAmount } = useProductPurchaseContext()

    const a = 0 == index ? product?.minPurchase : product?.calculatedPrices[index - 1].quantity + 1;

    return (
        <>
            {React.cloneElement(children, {
                key: index,
                disabled: false,
                onClick: () => setAmount(a),
                variant: (amount >= a && amount <= (product?.calculatedPrices[index]?.quantity ?? amount + 1)) || (index === product?.calculatedPrices.length - 1 && amount > product?.calculatedPrices[product?.calculatedPrices.length - 1]?.quantity) ? 'secondary' : 'white'
            })}
        </>
    )
}

export default PriceButtonGroupButton
