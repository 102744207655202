'use client'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { SwiperSlide } from "swiper/react";

import Image from "@components/atoms/image";
import MultiCarousel from "@components/molecules/multi-carousel";
import Background from "@components/atoms/background";
import Link from "@/components/atoms/link";

export type SliderItem = {
    url: string
}

const ImageSlider = ({ sliderItems, minHeight, speed, autoplayTimeout, autoSlide, hasNavigation, hasPagination }: any) => (
    <MultiCarousel
        className={'d-xl-block bg-color-secondary'}
        navigationVariant={'light'}
        hasNavigation={hasNavigation}
        hasPagination={hasPagination && 1 < sliderItems.length}
        paginationOutside={false}
        minHeight={minHeight?.value}
        speed={speed?.value}
        slidesPerView={1}
        breakpoints={{ 0: { slidesPerView: 1 } }}

        {... (true === autoSlide?.value && { autoplay: { delay: autoplayTimeout?.value } })}
    >
        {sliderItems?.map(({ mediaUrl, headline, description, url, newTab, buttonText, stoererText, backgroundColor, disclaimer }: any, i: number) => (
            mediaUrl = new URL(mediaUrl),
            url = url ? new URL(url) : url,
            <SwiperSlide key={'slider_' + i} className={'image-slider-background'} style={{
                ...((backgroundColor && backgroundColor.startsWith('#')) && { backgroundColor }),
                ...((backgroundColor && backgroundColor.startsWith('linear')) && { backgroundImage: backgroundColor }),
            }}>
                <Container style={{ maxWidth: hasNavigation ? 'calc(100vw - 100px)' : '100vw' }} className={'image-slider-height'}>
                    {(backgroundColor && backgroundColor.startsWith('url')) && <Background background={backgroundColor} headline={headline} priority={0 === i} />}

                    <Container className={'position-relative  image-slider-height  z-2'}>
                        <Row className={'h-100'}>
                            <Col className={'d-inline-flex flex-column justify-content-center align-items-center h-100 text-center p-0 m-0'}>
                                {headline && <p className={'H1-Headline-White fs-6 fs-md-4 fs-lg-1 mb-2'}>{headline}</p>}
                                {description && <div className={'Paragraph-Lead-White  d-none  d-md-inline-block  mb-3  fs-8  fs-lg-7  fs-xl-5'} dangerouslySetInnerHTML={{ __html: description }} />}
                                {disclaimer && <div className={'d-block d-md-none text-white fs-9'} dangerouslySetInnerHTML={{ __html: disclaimer }} />}
                                {url && (
                                    <Link
                                        href={true === newTab ? url.toString() : url.hostname + url.search}
                                        target={newTab ? '_blank' : '_self'}
                                        className={'fw-bold  text-white  shadow icon icon--chevron-right  icon--size__sm  icon--white  icon--reversed  pe-2  btn  btn-primary'}
                                    >
                                        <span className={'fs-9  fs-sm-8'}>{buttonText ?? 'Jetzt ansehen'}</span>
                                    </Link>
                                )}
                                {disclaimer && <div className={'position-absolute  d-none  d-md-block  bottom-0  text-white  fs-9  mb-4'} dangerouslySetInnerHTML={{ __html: disclaimer }} />}
                            </Col>
                            <Col className='position-relative p-0 m-0'>
                                <Image src={mediaUrl.pathname} alt={headline} className={'object-fit-cover'} priority={0 === i} sizes={'100vw'} unoptimized fill />

                                {/*
                                    <picture>
                                        <source media={`(max-width: 567px)`} srcSet={`${mediaUrl.pathname}?tx=w_260,c_fit,q_auto`} />
                                        <source media={`(min-width: 567px)`} srcSet={`${mediaUrl.pathname}?tx=w_480,c_fit,q_auto`} />
                                        <source media={`(min-width: 768px)`} srcSet={`${mediaUrl.pathname}?tx=w_705,c_fit,q_auto`} />

                                        <Image src={`${mediaUrl.pathname}?tx=w_705,c_fit,q_auto`} alt={headline} className={'object-fit-cover'} priority={0 === i} sizes={'100vw'} unoptimized fill />
                                    </picture>
                                */}

                                {stoererText && (
                                    <div
                                        className={'position-absolute  d-none  d-md-block  bg-primary  stoerer'}
                                        style={{
                                            boxShadow: '10px 5px 14px 0 rgba(0, 0, 0, 0.26)',
                                            zIndex: 999
                                        }}
                                    >
                                        <span
                                            className={'position-relative  d-inline-flex  fs-9  fs-md-7  fs-lg-4  fw-semibold  fst-normal  text-center  text-white  justify-content-center  h-100  align-items-center  p-2'}
                                            style={{
                                                transform: 'rotate(-15deg)',
                                                fontStretch: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                            }}
                                        >
                                            {stoererText}
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </SwiperSlide>
        ))}
    </MultiCarousel>
)

export default ImageSlider
