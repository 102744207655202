'use client'

import { useProductTableContext } from "@context/product-table"
import Button from "@components/atoms/button"
import FormControl from "@components/atoms/form-control"
import { useEffect, useState } from "react"

const RowQty = ({ productId, productName, purchaseUnit }: { productId: string, productName: string, purchaseUnit: number }) => {
    const [value, setValue] = useState<string>('0')

    const { products, setAmount } = useProductTableContext()
    const product = products[productId]

    const amount = (product?.quantity ?? 0)
    const prices = (product?.prices ?? [])
    const maxPurchase = (product?.maxPurchase ?? null)
    const customFields = { product_price_upon_request: false }

    const handleChangeAmount = (amount: number) => setAmount(productId, amount)

    useEffect(() => {
        const product = products[productId]
        setValue(((product?.quantity ?? 0) * purchaseUnit).toString())
    }, [products, productId, purchaseUnit])

    return (
        <div className={'d-inline-flex'}>
            <Button
                className={'p-0  border-0'}
                variant={'transparent'}
                onClick={() => handleChangeAmount(amount - 1)}
                aria-label={`Von Produkt ${productName} ${purchaseUnit} Stück abziehen `}
                disabled={amount <= 0 || 0 === prices.length || true === customFields.product_price_upon_request}
            >
                <span className={'d-inline-flex icon icon--minus icon--size__16'}></span>
            </Button>
            <FormControl
                id={`${productId}_quantity`}
                className={'mx-1  border-0  text-center  bg-white'}
                type={'input'}
                style={{ width: 75 }}
                value={value}
                onChange={(e) => setValue(e.currentTarget.value)}
                onBlur={(e) => handleChangeAmount(Number(e.target.value) / purchaseUnit)}
                aria-label={`Mengenfeld. Stück von Produkt ${productName} zum Warekorb hinzufügen.`}
                disabled={true === customFields.product_price_upon_request}
            />
            <Button
                className={'p-0  border-0'}
                variant={'transparent'}
                onClick={() => handleChangeAmount(amount + 1)}
                aria-label={`Zu Produkt ${productName} ${purchaseUnit} Stück hinzufügen `}
                disabled={(!!maxPurchase && (amount >= maxPurchase)) || 0 === prices.length || true === customFields.product_price_upon_request}
            >
                <span className={'d-inline-flex icon icon--plus icon--size__16'}></span>
            </Button>
        </div>

    )
}

export default RowQty
