import type { BackgroundProps } from "."

import Image from "next/image"

const Background = ({ background, headline, priority = true }: BackgroundProps) => {

    const url = new URL(background.replace('url(', '').replace(')', '').replace(';', ''))

    return (
        <>
            <Image src={url.pathname} alt={headline} className={'object-fit-cover'} priority={priority} sizes={'100vw'} unoptimized fill />
            {/*
        <picture>
            <source media={`(max-width: 567px)`} srcSet={`${url.pathname}?tx=w_567,c_fit,q_auto`} />
            <source media={`(min-width: 567px)`} srcSet={`${url.pathname}?tx=w_768,c_fit,q_auto`} />
            <source media={`(min-width: 768px)`} srcSet={`${url.pathname}?tx=w_992,c_fit,q_auto`} />
            <source media={`(min-width: 992px)`} srcSet={`${url.pathname}?tx=w_1200,c_fit,q_auto`} />
            <source media={`(min-width: 1200px)`} srcSet={`${url.pathname}?tx=w_1400,c_fit,q_auto`} />
            <source media={`(min-width: 1400px)`} srcSet={`${url.pathname}`} />

            <Image src={`${url.pathname}?tx=w_567,c_fit,q_auto`} alt={headline} className={'object-fit-cover'} priority={priority} sizes={'100vw'} unoptimized fill />
        </picture>
    */}
        </>
    )
}

export default Background
