'use client'

import React from "react";

import { useProductPurchaseContext } from "@context/product-purchase";
import ClientPrice from "@components/atoms/price/Client";

const QtyToNextGraduation = ({ nextPrice }: { nextPrice: any }) => {
    const { product, calculatedPrice, nextCalculatedPrice, amount } = useProductPurchaseContext()

    if (undefined === nextPrice || amount >= nextCalculatedPrice?.quantity) {
        return <></>
    }

    if (undefined === product || undefined === nextCalculatedPrice) {
        return <span>{Math.max(0, nextPrice.quantity - 2)} {'VE bis zur günstigeren Preisstaffel'}</span>
    }

    const { purchaseUnit, referenceUnit, packUnit, unit, taxId } = product

    return (
        <span>{calculatedPrice?.quantity + 1 - amount} {'VE bis zur günstigeren Preisstaffel'}: <ClientPrice unitPrice={nextCalculatedPrice?.unitPrice} purchaseUnit={purchaseUnit} referenceUnit={referenceUnit} taxId={taxId} /> */{packUnit ? packUnit : unit ? (unit.translated?.shortCode ?? unit.shortCode) : 'Einheit'}</span >
    )
}

export default QtyToNextGraduation
